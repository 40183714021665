export const PARIS_LINKS = [
  { title: 'Maths', url: '/cours/maths/paris' },
  { title: 'Anglais', url: '/cours/anglais/paris' },
  { title: 'Philosophie', url: '/cours/philosophie/paris' },
];

export const TOULOUSE_LINKS = [
  { title: 'Maths', url: '/cours/maths/toulouse' },
  { title: 'Physique', url: '/cours/physique/toulouse' },
  { title: 'Français', url: '/cours/francais/toulouse' },
];

export const NICE_LINKS = [
  { title: 'Maths', url: '/cours/maths/nice' },
  { title: 'Italien', url: '/cours/italien/nice' },
  { title: 'Anglais', url: '/cours/anglais/nice' },
];

export const LYON_LINKS = [
  { title: 'Maths', url: '/cours/maths/lyon' },
  { title: 'Allemand', url: '/cours/allemand/lyon' },
  { title: 'Espagnol', url: '/cours/espagnol/lyon' },
];
